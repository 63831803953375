/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('#slides').cycle({
          'fx': 'scrollHorz',
          "easing": "backout",
          'containerResize': true,
          'timeout': 7000,
          'speed': 900,
          'sync': true,
          'pager': '#pager',
          'prev': '.prev-slide',
          'next': '.next-slide',
          'cleartypeNoBg': true
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_booking': {
      init: function() {

        function isEmail(email) {
          var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          return regex.test(email);
        }

        $('#cl_reservation').change(function() {
              $('#time-booking-form .time-option').addClass('hidden-option');
              calendar_show($(this).val());
            });

            $('#time-booking-form').submit(function() {
              var error = false;

              $('#time-booking-form .error').remove();

              if($('#cl_chiropractic').val() === '') {
                $('#cl_chiropractic').after('<span class="error">Pakollinen kenttä / required field</span>');
                error = true;
              }
              if($('#cl_reservation').val() === '') {
                $('#cl_reservation').after('<span class="error">Pakollinen kenttä / required field</span>');
                error = true;
              }
              if($('#cl_first_name').val() === '') {
                $('#cl_first_name').after('<span class="error">Pakollinen kenttä / required field</span>');
                error = true;
              }
              if($('#cl_last_name').val() === '') {
                $('#cl_last_name').after('<span class="error">Pakollinen kenttä / required field</span>');
                error = true;
              }
              if($('#cl_address').val() === '') {
                $('#cl_address').after('<span class="error">Pakollinen kenttä / required field</span>');
                error = true;
              }
              if($('#cl_phone').val() === '') {
                $('#cl_phone').after('<span class="error">Pakollinen kenttä / required field</span>');
                error = true;
              }
              if($('#cl_email').val() === '') {
                $('#cl_email').after('<span class="error">Pakollinen kenttä / required field</span>');
                error = true;
              }
              if($('#cl_email2').val() === '') {
                $('#cl_email2').after('<span class="error">Pakollinen kenttä / required field</span>');
                error = true;
              }
              if(!isEmail($('#cl_email').val()) && $('#cl_email').val() !== '') {
                $('#cl_email').after('<span class="error">Lisää toimiva sähköpostiosoite / Add a valid e-mail address</span>');
                error = true;
              }
              if(!isEmail($('#cl_email2').val()) && $('#cl_email2').val() !== '') {
                $('#cl_email2').after('<span class="error">Lisää toimiva sähköpostiosoite / Add a valid e-mail address</span>');
                error = true;
              }

              if ( ( isEmail( $('#cl_email').val() ) && $('#cl_email').val() !== '' ) && ( isEmail( $('#cl_email2').val() ) && $('#cl_email2').val() !== '' ) && ( $('#cl_email').val() !== $('#cl_email2').val() ) ) {
                $('#cl_email2').after('<span class="error">Emails do not match</span>');
                error = true;
              }

              if($('#cl_dob_year').val() === '' || $('#cl_dob_month').val() === '' || $('#cl_dob_day').val() === '') {
                $('#cl_dob_year').after('<span class="error">Valitse syntymäaikasi / Choose date of birth</span>');
                error = true;
              }
              if($('#cl_date').val() === '' && $('.calendar-option').is(':visible')) {
                $('#cl_date_label').after('<span class="error">Valitse vapaa aika / Choose free time</span>');
                error = true;
              }
              if($('#cl_time').val() === '' && $('.time-option').is(':visible')) {
                $('#cl_time').after('<span class="error">Pakollinen kenttä / required field</span>');
                error = true;
              }

              if(!error) {
                var serialized_data = $('#time-booking-form').serialize();
                $('#time-booking-form').remove();
                $('.loader').removeClass('hidden-option');

                $.post(myAjax.ajaxurl, serialized_data, function(data) {
                  console.log(data);
                  if(data === 'OK') {
                    $('.success').removeClass('hidden-option');
                    $('.loader').addClass('hidden-option');
                  }
                });
              }

              return false;
            });

            function calendar_show(type_id) {
              var datestimes = [],
                dates = [],
                times = [];

              $('#time-booking-form .calendar-option .calendar').html('<img src="'+ myAjax.templateurl +'/dist/images/loader.gif" alt="loading...." class="hidden-option calendar-option">');
              $('#time-booking-form .calendar-option').removeClass('hidden-option');

              $.post(myAjax.ajaxurl, { chiropractic: $('#cl_chiropractic').val(), reservation_type: type_id, action: 'build_calendar' }, function(data) {

                $('#time-booking-form .calendar-option .calendar').remove();
                $('#time-booking-form .calendar-option').append('<div class="calendar" />');

                if(data !== '') {
                  console.log(data);
                  datestimes = $.parseJSON(data);
                  $.each(datestimes, function(key, value) {
                    dates.push(key);
                  });

                  $('.calendar').datepicker({
                    dateFormat: 'dd.mm.yy',
                    numberOfMonths: 2,
                    minDate: 0,
                    beforeShowDay: function (d) {
                      var dmy = '';

                      if(d.getDate()<10) {
                        dmy += "0"; 
                      }
                          
                      dmy += d.getDate() + '.'; 

                      if(d.getMonth()<9) {
                        dmy += "0";
                      }

                      dmy += (d.getMonth()+1) + '.';

                      dmy += d.getFullYear();
                      
                      if ($.inArray(dmy, dates) !== -1) {                
                          return [true, "", "Vapaa"]; 
                      } else{
                        return [false,"", "Varattu"]; 
                      }
                    },
                    onSelect: function(d) {
                        times = datestimes[d].time;
                        $('#cl_date').val(d);

                        $('#cl_time').children().remove().end().append('<option value="" select>Valitse</option>') ;

                        $.each(times, function(key, value) {
                            $('#cl_time').append('<option value="' + key + '">' + key + '</option>');
                        });

                        $('#time-booking-form .time-option').removeClass('hidden-option');
                    }
                  });
                } else {
                  $('.calendar').html('<strong>Hoitoaikoja ei ole tällä hetkellä varattavissa.</strong>');
                }

                $('#time-booking-form img.calendar-option').addClass('hidden-option');
              });
            }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
